import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { LetterPreviewDescription } from "./components/LetterPreviewDescription/LetterPreviewDescription";
import { LetterPreviewImage } from "./components/LetterPreviewImage/LetterPreviewImage";
import { LetterPreviewInfo } from "./components/LetterPreviewInfo/LetterPreviewInfo";
import { LetterPreviewStatusBar } from "./components/LetterPreviewStatusBar/LetterPreviewStatusBar";

// CSS
import styles from "./letter-preview.module.scss";

// Hooks
import { useLetterPreview } from "./hooks/useLetterPreview";
import { useMeasure } from "@uidotdev/usehooks";

// Models
import { ReplyLetter } from "models/letters/ReplyLetter";
import { SentLetter } from "models/letters/SentLetter";

export const LetterPreview = ({
    className,
    isLoading,
    letter,
    letterType,
    linkParams,
}) => {
    /**
     * Custom Hooks
     */

    const { imageSrc, tagText } = useLetterPreview({
        letter,
        letterType,
    });
    const [contentRef, { height }] = useMeasure();

    /**
     * End Hooks
     */

    function renderLetterPreview() {
        return (
            <Link
                data-cy={`letter-preview-link-container-${letter?.recipient?.name}`}
                to={linkParams}
            >
                <div className={cx(styles.letterPreview, className)}>
                    <div className={styles.content} ref={contentRef}>
                        <LetterPreviewInfo
                            imageSrc={imageSrc}
                            isLoading={isLoading}
                            letter={letter}
                            tagText={tagText}
                        />

                        <LetterPreviewStatusBar
                            isLoading={isLoading}
                            letter={letter}
                        />
                        <LetterPreviewDescription letter={letter} />
                    </div>
                    <LetterPreviewImage
                        height={height}
                        imageSrc={imageSrc}
                        isLoading={isLoading}
                        letter={letter}
                    />
                </div>
            </Link>
        );
    }

    function renderLoading() {
        return (
            <div className={cx(styles.letterPreview)}>
                <div className={cx(styles.letterPreview, className)}>
                    <div className={styles.content} ref={contentRef}>
                        <LetterPreviewInfo isLoading={isLoading} />
                        <LetterPreviewStatusBar isLoading={isLoading} />
                        <LetterPreviewDescription isLoading={isLoading} />
                    </div>
                    <LetterPreviewImage isLoading={isLoading} />
                </div>
            </div>
        );
    }

    return isLoading ? renderLoading() : renderLetterPreview();
};

LetterPreview.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    letter: PropTypes.oneOfType([
        PropTypes.instanceOf(ReplyLetter),
        PropTypes.instanceOf(SentLetter),
    ]),
    letterType: PropTypes.string,
    linkParams: PropTypes.object,
};
