import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LetterPreview } from "../../../components/LetterPreview/LetterPreview";
import { Panel } from "components/Panel/Panel";
import { Tabs } from "components/Tabs/Tabs";
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-main-letters.module.scss";

// Enums
import { LetterType } from "enums/LetterType";

// Localization
import { useTranslation } from "localization/localization";

// Media
import uspsLogoSrc from "media/logos/usps.svg";

// Models
import { ReplyLetter } from "models/letters/ReplyLetter";
import { SentLetter } from "models/letters/SentLetter";

export const DashboardMainLetters = ({
    history,
    lettersReplied,
    lettersSent,
    moreLettersRepliedCursor,
    moreLettersSentCursor,
}) => {
    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    /**
     * useState
     **/

    const [letters, setLetters] = useState({
        list: lettersSent,
        type: LetterType.SENT,
    });

    /**
     * useEffect
     **/

    useEffect(() => {
        if (letters.list === null) {
            setLetters((prev) => ({
                ...prev,
                list: lettersSent,
                type: LetterType.SENT,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lettersSent]);

    /**
     * End Hooks
     **/

    function renderContent() {
        if (letters.list === null) {
            return renderLoading();
        }
        if (letters.list && letters.list.length) {
            return renderLetters();
        }
        if (letters.list && !letters.list.length) {
            return renderEmptyState();
        }
    }

    function renderEmptyState() {
        if (letters.type === LetterType.REPLIED) {
            return (
                <div className={styles.replied}>
                    <div className={styles.content}>
                        <div className={styles.logo}>
                            <img alt="USPS logo" src={uspsLogoSrc} />
                        </div>
                        <div className={styles.text}>
                            {t("letters.digital_preview", { ns: "dashboard" })}
                        </div>
                    </div>
                    <div
                        className={styles.button}
                        data-cy="dashboard-main-letters-empty-replied-button"
                    >
                        <a
                            href="https://informeddelivery.usps.com/box/pages/intro/start.action?utm_medium=digital&utm_source=ID&utm_campaign=sandboxx&utm_content=dashboard"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <ButtonSecondary text="Get Started">
                                <Text>
                                    {t("get_started", { ns: "common" })}
                                </Text>
                            </ButtonSecondary>
                        </a>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.empty}>
                    <span>
                        {t("letters.sent_letters_empty", { ns: "dashboard" })}
                    </span>
                </div>
            );
        }
    }

    function renderLoading() {
        return (
            <div className={styles.list}>
                <LetterPreview
                    className={styles.letterPreview}
                    isLoading={true}
                    history={history}
                    key={0}
                />
                <LetterPreview
                    className={styles.letterPreview}
                    isLoading={true}
                    history={history}
                    key={1}
                />
                <LetterPreview
                    className={styles.letterPreview}
                    isLoading={true}
                    history={history}
                    key={2}
                />
                {renderShowAll()}
            </div>
        );
    }

    function renderLetters() {
        const items = letters.list.slice(0, 3).map((letter, key) => {
            const { id, mailboxxOrderId } = letter;
            const urlId =
                letters.type === LetterType.REPLIED ? mailboxxOrderId : id;
            const linkParams = {
                pathname: `/letters/sent/${urlId}`,
                state: { letter, letterType: letters.type },
            };
            return (
                <LetterPreview
                    className={styles.letterPreview}
                    isLoading={false}
                    key={urlId}
                    letter={letter}
                    letterType={letters.type}
                    linkParams={linkParams}
                />
            );
        });
        return (
            <div className={styles.list}>
                {items}
                {renderShowAll()}
            </div>
        );
    }

    function renderShowAll() {
        return (
            <Link
                data-cy="dashboard-main-letters-show-all"
                to={{
                    pathname: "/letters/sent",
                    state: {
                        cursorReplied: moreLettersRepliedCursor,
                        cursorSent: moreLettersSentCursor,
                        lettersReplied,
                        lettersSent,
                    },
                }}
            >
                <div className={styles.showAll}>
                    <Text>{t("show_all", { ns: "common" })}</Text>
                    <ChevronRightIcon className={styles.icon} />
                </div>
            </Link>
        );
    }

    function renderTabs() {
        const tabsData = [
            {
                cyId: "dashboard-main-letters-sent-button",
                id: LetterType.SENT,
                index: 0,
                isActive: letters.type === LetterType.SENT,
                onClick: () =>
                    setLetters((prev) => ({
                        ...prev,
                        list: lettersSent,
                        type: LetterType.SENT,
                    })),
                text: t("sent", { ns: "common" }),
            },
            {
                cyId: "dashboard-main-letters-replied-button",
                id: LetterType.REPLIED,
                index: 1,
                isActive: letters.type === LetterType.REPLIED,
                onClick: () =>
                    setLetters((prev) => ({
                        ...prev,
                        list: lettersReplied,
                        type: LetterType.REPLIED,
                    })),
                text: t("replied", { ns: "common" }),
            },
        ];
        return <Tabs data={tabsData} />;
    }

    return (
        <Panel classes={styles.dashboardMainLetters}>
            <div className="panel__header">
                <span className="panel__header__text">
                    {t("history", { ns: "common" }).toUpperCase()}
                </span>
            </div>
            {renderTabs()}
            {renderContent()}
        </Panel>
    );
};

DashboardMainLetters.propTypes = {
    history: PropTypes.object,
    lettersReplied: PropTypes.arrayOf(PropTypes.instanceOf(ReplyLetter)),
    lettersSent: PropTypes.arrayOf(PropTypes.instanceOf(SentLetter)),
    moreLettersRepliedCursor: PropTypes.string,
    moreLettersSentCursor: PropTypes.string,
};
