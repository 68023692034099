import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { BundlesContext } from "../../../context/bundles";
import { ContactsContext } from "../../../context/contacts";
import { CreditsContext } from "../../../context/credits";
import { DepsContext } from "../../../context/deps";
import { HeaderBarContext } from "context/headerBar";
import { NotificationBarContext } from "../../../context/notificationBar";
import { PhoneVerificationContext } from "context/phoneVerification";
import { PurchaseContext } from "../../../context/purchase";

// Platforms
import { Analytics } from "platforms/analytics";

// Utils
import { generateImagePath } from "utils/imageUtils";
import { SandboxxRestAPI, CurrentUser } from "utils/sandboxx";
import { scrollToTop } from "utils/scrollUtils";

export const useProfile = (props) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * Constants
     */

    const user = CurrentUser.getUser();

    /**
     * useContext
     */

    const { fetchBundles } = useContext(BundlesContext);
    const { fetchContacts } = useContext(ContactsContext);
    const { credits, fetchCredits } = useContext(CreditsContext);
    const { depsLocations, fetchDepsLocations } = useContext(DepsContext);
    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);
    const { togglePhoneVerificationModal } = useContext(
        PhoneVerificationContext
    );
    const { fetchRecentPaymentMethod, fetchSavedCards, setShowPurchaseModal } =
        useContext(PurchaseContext);

    /**
     * useState
     */

    const [componentState, setState] = useState({
        depLocation: null,
        display: {
            showImageCropModal: false,
            shouldShowDepModal: false,
            shouldShowPersonaModal: false,
            shouldShowShipDateModal: false,
        },
        hasSentLetter: null,
        lettersSent: { hasSentLetter: null, count: null, loading: true },
        message: {
            imageFile: null,
            imageName: null,
            imagePreviewUrl: null,
            imageUploadSuccess: false,
            imageUploadUrl: null,
        },
        profileInfo: {},
        purchase: {
            bundle: null,
            giftCard: null,
            originalBundle: null,
            totalPrice: null,
        },
        shouldShowOnboardingNotification: false,
        shouldSkipOnboarding: JSON.parse(
            localStorage.getItem("sandboxxSkipOnboarding")
        ),
        taxIdentity: null,
    });
    const [imagePreview, setImagePreview] = useState({
        imageFile: null,
        imageName: null,
        imagePreviewUrl: null,
    });
    const [showImageCropModal, setShowImageCropModal] = useState(false);
    const [showProfileDeleteAccountModal, setShowProfileDeleteAccountModal] =
        useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderBarContent({
            text: "Profile",
            textSecondary: "View and edit your information and address book",
        });
        scrollToTop();
        fetchAccount();
        fetchBundles();
        fetchContacts();
        fetchCredits();
        fetchDashboard();
        fetchDepsLocations({
            branchId:
                (user.personas[2] && user.personas[2].id) ||
                user.branchId ||
                "UNKNOWN",
        });
        fetchProfile();
        fetchRecentPaymentMethod();
        fetchSavedCards();
        fetchSentLetters();
        handleShowCompleteProfileNotification();
        checkForProfileUpdateSuccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (componentState.message.imageUploadSuccess) {
            SandboxxRestAPI.updateUserAvatar(
                componentState.message.imageUploadUrl,
                onAvatarUploadSuccess,
                onAvatarUploadFailure,
                onAvatarUploadError
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentState.message.imageUploadSuccess]);

    /**
     * End Hooks
     */

    function checkForProfileUpdateSuccess() {
        if (localStorage.getItem("shouldShowProfileUpdateSuccess") === "true") {
            showNotification({
                text: "You've successfully updated your profile.",
                type: "success",
            });
            localStorage.setItem("shouldShowProfileUpdateSuccess", false);
        }
    }

    function fetchAccount() {
        SandboxxRestAPI.getAccount(onFetchAccountSuccess);
    }

    function fetchDashboard() {
        return SandboxxRestAPI.getDashboard(onFetchDashboardSuccess);
    }

    function fetchProfile() {
        return SandboxxRestAPI.getProfile(onFetchProfileSuccess);
    }

    function fetchSentLetters() {
        return SandboxxRestAPI.getLettersSent(
            "", // No cursor
            onFetchSentLettersSuccess,
            onFetchSentLettersError,
            onFetchSentLettersError
        );
    }

    /* istanbul ignore next */
    async function handleAvatarUpload(croppedImage) {
        const imagePath = generateImagePath();
        const imageUploadFile = croppedImage.imageFile;
        return SandboxxRestAPI.putImageCloudfront(
            imagePath,
            imageUploadFile,
            () => onImageUploadSuccess(imagePath),
            onImageUploadFailure,
            onImageUploadFailure
        );
    }

    function handleGoToContact({ suggestion: contact }) {
        history.push(`/contact/${contact.id}`, { contact });
    }

    function handleGoToOnboarding() {
        Analytics.trackEvent("firebase", {
            event: "onboarding_fac_complete_profile_option",
            data: { facOnboardingBase: "PI" },
        });
        localStorage.setItem("sandboxxSkipOnboarding", false);
        CurrentUser.toggleOnboarding(true);
        history.push("/signin");
    }

    /* istanbul ignore next */
    function handleImageCancel() {
        setImagePreview((prevState) => ({
            ...prevState,
            imageFile: null,
            imageName: null,
            imagePreviewUrl: null,
        }));
        setShowImageCropModal(false);
    }

    /* istanbul ignore next */
    function handleImageFileSelect(e) {
        e.persist();
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];

        reader.onloadend = () => {
            setImagePreview((prevState) => ({
                ...prevState,
                imageFile: file,
                imageName: file.name,
                imagePreviewUrl: reader.result,
            }));
            setShowImageCropModal(true);
        };
        reader.readAsDataURL(file);
    }

    function handlePurchaseModalToggle() {
        setShowPurchaseModal((prev) => !prev);
    }

    /* istanbul ignore next */
    function handleSaveCroppedImage(croppedImage) {
        const { imageFile, imagePreviewUrl } = croppedImage;
        const placeHolderArray = imagePreview;
        setImagePreview({
            ...placeHolderArray,
            imageFile,
            imagePreviewUrl,
        });
        setShowImageCropModal(false);
        handleAvatarUpload(croppedImage);
    }

    function handleShowCompleteProfileNotification({
        overrideBool,
        shouldOverride,
    } = {}) {
        const { shouldSkipOnboarding } = componentState;
        if (shouldOverride) {
            setState((prevState) => ({
                ...prevState,
                shouldShowOnboardingNotification: overrideBool,
            }));
        } else if (shouldSkipOnboarding) {
            setState((prevState) => ({
                ...prevState,
                shouldShowOnboardingNotification: true,
            }));
        }
    }

    function handleShowDepModal(bool) {
        setState((prevState) => ({
            ...prevState,
            display: {
                ...prevState.display,
                shouldShowDepModal: bool,
            },
        }));
    }

    function handleShowPersonaSelectionModal(bool) {
        setState((prev) => ({
            ...prev,
            display: {
                ...prev.display,
                shouldShowPersonaModal: bool,
            },
        }));
    }

    function handleShowShipDateModal(bool) {
        setState((prev) => ({
            ...prev,
            display: {
                ...prev.display,
                shouldShowShipDateModal: bool,
            },
        }));
    }

    /* istanbul ignore next */
    function onAvatarUploadError() {
        showNotification({
            text: "There was an error uploading your avatar. Please try again.",
            type: "warning",
        });
    }

    /* istanbul ignore next */
    function onAvatarUploadFailure() {
        showNotification({
            text: "There was an error uploading your avatar. Please try again.",
            type: "warning",
        });
    }

    /* istanbul ignore next */
    function onAvatarUploadSuccess() {
        SandboxxRestAPI.getAccount((res) => {
            const { user, onboarding, verification } = res;
            CurrentUser.storeUser(user, verification, onboarding);
        }).then(() => {
            handleImageCancel();
            window.location.reload();
        });
    }

    function onFetchAccountSuccess({ depLocation }) {
        setState((prevState) => ({
            ...prevState,
            depLocation,
            display: {
                ...prevState.display,
                shouldShowDepModal: false,
            },
        }));
    }

    function onFetchDashboardSuccess({ taxIdentity }) {
        setState((prevState) => ({ ...prevState, taxIdentity }));
    }

    function onFetchProfileSuccess(profile) {
        setState((prevState) => ({
            ...prevState,
            profileInfo: {
                ...prevState.profileInfo,
                ...profile,
            },
        }));
    }

    function onFetchSentLettersError() {
        setState((prevState) => ({
            ...prevState,
            lettersSent: {
                ...prevState.lettersSent,
                loading: false,
            },
        }));
        showNotification({
            text: "There was an issue fetching your sent Letters. Please try again",
            type: "warning",
        });
    }

    function onFetchSentLettersSuccess(res) {
        setState((prev) => ({
            ...prev,
            lettersSent: {
                ...prev.lettersSent,
                count: res.count,
                hasSentLetter: !!res.count,
                loading: false,
            },
        }));
    }

    /* istanbul ignore next */
    function onImageUploadFailure(err) {
        setState((prevState) => ({
            ...prevState,
            message: {
                ...prevState.message,
                imageFile: null,
                imagePreviewUrl: null,
                imageUploadSuccess: false,
                imageUploadUrl: null,
            },
        }));
        showNotification({
            text: "There was an error uploading your image. Please try again.",
            type: "warning",
        }); // Callback
    }

    /* istanbul ignore next */
    function onImageUploadSuccess(imagePath) {
        setState((prevState) => ({
            ...prevState,
            message: {
                ...prevState.message,
                imageUploadSuccess: true,
                imageUploadUrl: imagePath,
            },
        }));
    }

    async function onProfileUpdateSuccess() {
        Promise.all([
            fetchProfile(),
            SandboxxRestAPI.getAccount((res) => {
                const { user, onboarding, verification } = res;
                CurrentUser.storeUser(user, verification, onboarding);
            }),
        ]).then(() => {
            window.location.reload();
        });
    }

    return {
        componentState,
        credits,
        depsLocations,
        fetchAccount,
        fetchDepsLocations,
        handleImageCancel,
        imagePreview,
        handleGoToContact,
        handleGoToOnboarding,
        handleImageFileSelect,
        handlePurchaseModalToggle,
        handleSaveCroppedImage,
        handleShowCompleteProfileNotification,
        handleShowDepModal,
        handleShowPersonaSelectionModal,
        handleShowShipDateModal,
        onProfileUpdateSuccess,
        showImageCropModal,
        showProfileDeleteAccountModal,
        setShowProfileDeleteAccountModal,
        togglePhoneVerificationModal,
    };
};
