/**
 * Informs what the UI state of the LetterPreviewStatusBar component
 * Determined by the `status` and `tracking_status` of sent letter data
 */
export const SentLetterVisualStatus = Object.freeze({
    DELIVERED: "DELIVERED",
    IN_REVIEW: "IN_REVIEW",
    ON_HOLD: "ON_HOLD",
    RECEIVED: "RECEIVED",
    SHIPPED: "SHIPPED",
});
