import { DateTime } from "luxon";

// Utils
import { titleCase } from "utils/textUtils";

/**
 * @typedef {Object} ReplyLetter
 * @property {String} created_at
 * @property {String} firstScanDate
 * @property {String} id
 * @property {String} mailboxxOrderId
 * @property {String} pretty_status
 * @property {String} prettyScanStatus
 * @property {Object} recipient
 * -- @property {Object} recipient.address
 * ---- @property {String} recipient.address.city
 * ---- @property {String} recipient.address.state
 * -- @property {Object} recipient.name
 * @property {String} recipientFirstName
 * @property {String} recipientLastName
 * @property {String} recipientName
 * @property {String} scanCity
 * @property {String} scanState
 * @property {String} scanStatus
 * @property {String} status
 */

/**
 * @class representing reply letter data
 */
export class ReplyLetter {
    /**
     * @param {Object} letterData contains API reply letter data
     */
    constructor(letterData) {
        const formattedLetterData = formatReplyLetterData(letterData);
        Object.keys(formattedLetterData).forEach((key) => {
            this[key] = formattedLetterData[key];
        });
    }

    get createdAtPretty() {
        return DateTime.fromISO(this.created_at).toLocaleString({
            day: "numeric",
            month: "short",
        });
    }
}

/**
 * Method that formats ReplyLetter data so that it can be better used in
 * UIs that also incorporate SentLetter and SentNewsletter
 *
 * @param {Object} letterData
 * @returns {Object} see ReplyLetter typedef for schema
 */
function formatReplyLetterData(letterData) {
    return {
        ...letterData,
        created_at: letterData.firstScanDate,
        id: letterData.mailboxxOrderId,
        pretty_status: letterData.prettyScanStatus || letterData.pretty_status,
        status: letterData.prettyScanStatus || letterData.pretty_status,
        recipient: {
            address: {
                city:
                    titleCase(letterData.scanCity) ||
                    letterData.recipient.address.city,
                state:
                    letterData.scanState || letterData.recipient.address.state,
            },
            name: letterData.recipientName,
        },
    };
}
