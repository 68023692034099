import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { MultipleImagesIcon } from "components/Icons/MultipleImagesIcon";
import { Skeleton } from "@mui/material";
// CSS
import styles from "./letter-preview-image.module.scss";

// Media
import imageEmptySrc from "media/icons/image-empty.svg";

// Models
import { ReplyLetter } from "models/letters/ReplyLetter";
import { SentLetter } from "models/letters/SentLetter";

export const LetterPreviewImage = ({ height, imageSrc, isLoading, letter }) => {
    function renderContent() {
        return (
            <div
                className={cx(styles.letterPreviewImage, {
                    [styles["letterPreviewImage--empty"]]:
                        imageSrc === imageEmptySrc,
                })}
                style={{
                    backgroundImage: `url(${imageSrc})`,
                    width: height,
                }}
            >
                {letter.customPhotoArray?.length > 1 && (
                    <MultipleImagesIcon
                        className={styles.icon}
                        color="white"
                        height="16px"
                        width="16px"
                    />
                )}
            </div>
        );
    }

    function renderLoading() {
        return (
            <div
                className={cx(styles.letterPreviewImage, {
                    [styles["letterPreviewImage--empty"]]:
                        imageSrc === imageEmptySrc,
                })}
                style={{
                    backgroundImage: `url(${imageSrc})`,
                    height: 150,
                    width: 150,
                }}
            >
                <Skeleton
                    className={cx("skeleton")}
                    style={{ height: "100%" }}
                />
            </div>
        );
    }

    return isLoading ? renderLoading() : renderContent();
};

LetterPreviewImage.propTypes = {
    height: PropTypes.number,
    imageSrc: PropTypes.string,
    isLoading: PropTypes.bool,
    letter: PropTypes.oneOfType([
        PropTypes.instanceOf(ReplyLetter),
        PropTypes.instanceOf(SentLetter),
    ]),
};
