// Components
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import LanguageIcon from "@mui/icons-material/Language";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";

export const listIconItems = [
    {
        icon: <FlightTakeoffIcon />,
        subtitle:
            "Next-day base delivery for all letters sent before 4:30pm ET on weekdays*",
    },
    {
        icon: <LanguageIcon />,
        subtitle: "Tracking and delivery confirmation",
    },
    {
        icon: <ReplyOutlinedIcon />,
        subtitle:
            "Pre-addressed return envelope, stationery, and prepaid postage (for verified addresses)**",
    },
    {
        icon: <PhotoSizeSelectActualOutlinedIcon />,
        subtitle: "One photo included per Letter",
    },
    {
        icon: <EventOutlinedIcon />,
        subtitle: "Access to base-specific weekly training updates",
    },
];

export const sliderImages = [
    "https://dkpdw0r5j9hhy.cloudfront.net/interstitial/letter-value-props/web/your-message.png",
    "https://dkpdw0r5j9hhy.cloudfront.net/interstitial/letter-value-props/web/stationery.png",
    "https://dkpdw0r5j9hhy.cloudfront.net/interstitial/letter-value-props/web/envelopes.png",
    "https://dkpdw0r5j9hhy.cloudfront.net/interstitial/letter-value-props/web/tracking.png",
    "https://dkpdw0r5j9hhy.cloudfront.net/interstitial/letter-value-props/web/go-premium.png",
];

export const sliderText = [
    {
        title: "Your Message",
        subtitle: "Share your moments and be a part of your recruit's journey",
    },
    {
        title: "Stationery",
        subtitle: "Free stationery and return address sheet for your recruit",
    },
    {
        title: "Envelope",
        subtitle: "Your letter comes with a free outbound and inbound envelope",
    },
    {
        title: "Tracking",
        subtitle: "Track your letter as it makes its way to your recruit",
    },
    {
        title: "Go Premium",
        subtitle: "Save and get more with our Sandboxx Plus bundle",
    },
];
