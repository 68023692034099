import React from "react";
import PropTypes from "prop-types";

// Components
import { Box } from "@mui/material";

export const CircleIconWithBackground = ({ classNames, Icon, styles }) => {
    return (
        <Box
            className={classNames.container}
            sx={{
                ...styles.container,
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Icon
                className={classNames.icon}
                sx={{
                    ...styles.icon,
                    position: "relative",
                    zIndex: 1,
                }}
            />
            <Box
                className={classNames.background}
                sx={{
                    ...styles.background,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    backgroundColor: "var(--white)",
                    zIndex: 0,
                }}
            />
        </Box>
    );
};

CircleIconWithBackground.defaultProps = {
    classNames: { background: "", container: "", icon: "" },
    styles: { background: {}, container: { height: 20, width: 20 }, icon: {} },
};

CircleIconWithBackground.propTypes = {
    classNames: PropTypes.object,
    Icon: PropTypes.elementType.isRequired,
    styles: PropTypes.object,
};
