import React from "react";
import cx from "classnames";

// Components
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircleIconWithBackground } from "components/Icons/MUI/CircleIconWithBackground";
import InfoIcon from "@mui/icons-material/Info";
import { Text } from "components/Text/Text";

// CSS
import styles from "./letter-preview-status-bar-node.module.scss";

// Enums
import { NodeVisualStatus } from "./enums/LetterPreviewStatusBarNodeState";

export const LetterPreviewStatusBarNode = ({ text, visualStatus }) => {
    if (visualStatus === NodeVisualStatus.ERROR) {
        return (
            <div
                className={cx(
                    styles.letterPreviewStatusBarNode,
                    styles["letterPreviewStatusBarNode--error"]
                )}
            >
                <div className={styles.icon}>
                    <CircleIconWithBackground
                        classNames={{
                            background: "",
                            container: "",
                            icon: styles.icon,
                        }}
                        Icon={CancelIcon}
                    />
                </div>
                <Text classes={styles.text}>{text}</Text>
            </div>
        );
    }
    if (visualStatus === NodeVisualStatus.INFO) {
        return (
            <div
                className={cx(
                    styles.letterPreviewStatusBarNode,
                    styles["letterPreviewStatusBarNode--info"]
                )}
            >
                <div className={styles.icon}>
                    <CircleIconWithBackground
                        classNames={{
                            background: "",
                            container: "",
                            icon: styles.icon,
                        }}
                        Icon={InfoIcon}
                    />
                </div>
                <Text classes={styles.text}>{text}</Text>
            </div>
        );
    }
    if (visualStatus === NodeVisualStatus.SUCCESS) {
        return (
            <div
                className={cx(
                    styles.letterPreviewStatusBarNode,
                    styles["letterPreviewStatusBarNode--success"]
                )}
            >
                <div className={styles.icon}>
                    <CircleIconWithBackground
                        classNames={{
                            background: "",
                            container: "",
                            icon: styles.icon,
                        }}
                        Icon={CheckCircleIcon}
                    />
                </div>
                <Text classes={styles.text}>{text}</Text>
            </div>
        );
    }
    return (
        <div
            className={cx(
                styles.letterPreviewStatusBarNode,
                styles["letterPreviewStatusBarNode--empty"]
            )}
        >
            <div className={styles.icon}>
                <div />
            </div>
            <Text classes={styles.text}>{text}</Text>
        </div>
    );
};
