import { DateTime } from "luxon";

// Enums
import { SentLetterStatus } from "enums/letters/SentLetterStatus";
import { SentLetterTrackingStatus } from "enums/letters/SentLetterTrackingStatus";
import { SentLetterVisualStatus } from "enums/letters/SentLetterVisualStatus";

/**
 * @typedef {Object} SentLetter
 * @property {String} contentURL
 * @property {String} created_at
 * @property {Array[String]} customPhotoArray
 * @property {String} custom_message
 * @property {String} delivery_date
 * @property {String} expected_delivery_date
 * @property {String} id
 * @property {String} photo_url
 * @property {Boolean} postagePaid
 * @property {String} pretty_status
 * @property {String} pretty_tracker_status
 * @property {Object} recipient
 * -- @property {Object} recipient.address
 * ---- @property {String} recipient.address.city
 * ---- @property {String} recipient.address.line1
 * ---- @property {String} recipient.address.line2
 * ---- @property {String} recipient.address.state
 * ---- @property {String} recipient.address.zipcode
 * -- @property {Boolean} recipient.connected
 * -- @property {String} recipient.id
 * -- @property {String} recipient.name
 * @property {Object} sender
 * -- @property {Object} sender.address
 * ---- @property {String} sender.address.city
 * ---- @property {String} sender.address.line1
 * ---- @property {String} sender.address.line2
 * ---- @property {String} sender.address.state
 * ---- @property {String} sender.address.zipcode
 * -- @property {String} sender.id
 * -- @property {String} sender.name
 * @property {String} shippingCarrier
 * @property {String} status
 * @property {String} status_description
 * @property {String} tracking_number
 * @property {String} tracker_status
 * @property {String} tracker_url
 */

/**
 * @class representing sent letter data
 *
 * TODO: Replace recipient/sender/address content with new respective models
 */
export class SentLetter {
    /**
     * @param {Object} letterData contains API sent letter data
     */
    constructor(letterData) {
        Object.keys(letterData).forEach((key) => {
            this[key] = letterData[key];
        });
    }

    get createdAtPretty() {
        if (this.created_at) {
            return DateTime.fromISO(this.created_at).toLocaleString({
                day: "numeric",
                month: "short",
            });
        }
        return null;
    }

    get deliveryDatePretty() {
        if (this.delivery_date) {
            return DateTime.fromISO(this.delivery_date).toLocaleString({
                day: "numeric",
                month: "short",
            });
        }
        return null;
    }

    get expectedDeliveryDatePretty() {
        if (this.expected_delivery_date) {
            return DateTime.fromISO(this.expected_delivery_date).toLocaleString(
                {
                    day: "numeric",
                    month: "short",
                }
            );
        }
        return null;
    }

    get visualStatus() {
        switch (this.status) {
            case SentLetterStatus.NEW:
            case SentLetterStatus.PRINTING:
            case SentLetterStatus.PRINTED:
                return SentLetterVisualStatus.RECEIVED;
            case SentLetterStatus.ON_HOLD:
                return SentLetterVisualStatus.ON_HOLD;
            case SentLetterStatus.IN_REVIEW:
                return SentLetterVisualStatus.IN_REVIEW;
            case SentLetterStatus.SENT:
                switch (this.tracker_status) {
                    case SentLetterTrackingStatus.DELIVERED:
                        return SentLetterVisualStatus.DELIVERED;
                    case SentLetterTrackingStatus.IN_TRANSIT:
                    case SentLetterTrackingStatus.NO_TRACKING:
                    case SentLetterTrackingStatus.PENDING:
                    default:
                        return SentLetterVisualStatus.SHIPPED;
                }
            default:
                return null;
        }
    }
}
