/**
 * Represents the Sandboxx status of a sent letter
 */
export const SentLetterStatus = Object.freeze({
    ARCHIVE: "ARCHIVE",
    IN_REVIEW: "IN_REVIEW",
    NEW: "NEW", //processing
    ON_HOLD: "ONHOLD",
    PRINTING: "PRINTING",
    PRINTED: "PRINTED",
    SENT: "SENT",
});
