import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./letter-preview-description.module.scss";

// Enums
import { SentLetterVisualStatus } from "enums/letters/SentLetterVisualStatus";

// Models
import { SentLetter } from "models/letters/SentLetter";
import { ShippingCarrier } from "enums/letters/ShippingCarrier";

export const LetterPreviewDescription = ({ className, isLoading, letter }) => {
    // We can only provide tracking and delivery confirmation for Fedex
    const isFedex = letter?.shippingCarrier === ShippingCarrier.FEDEX;
    const visualStatus = letter?.visualStatus;

    if (isLoading) {
        return (
            <div
                className={cx(
                    styles.letterPreviewDescription,
                    styles["letterPreviewDescription--loading"],
                    className
                )}
            >
                <Skeleton className={cx(styles.skeleton, "skeleton")} />
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.DELIVERED) {
        const text = letter.delivery_date
            ? `Delivered on ${letter.deliveryDatePretty}`
            : "Delivered";
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>{text}</Text>
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.IN_REVIEW) {
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>
                    Delivery will be updated once your recipient's address has
                    been reviewed by our team
                </Text>
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.RECEIVED) {
        const text = isFedex
            ? "Shipping Soon. Tracking and delivery confirmation will be available once shipped."
            : "Shipping soon. Precise tracking and delivery confirmation is not available for this letter.";
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>{text}</Text>
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.ON_HOLD) {
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>
                    Delivery is on hold until your recipient's address is fixed
                </Text>
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.SHIPPED) {
        const text =
            isFedex && letter.expected_delivery_date
                ? `Expected delivery by ${letter.expectedDeliveryDatePretty}`
                : "Delivery expected soon. Precise tracking and delivery confirmation is not available for this letter.";
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>{text}</Text>
            </div>
        );
    }
    return (
        <div className={cx(styles.letterPreviewDescription, className)}>
            <Text>Letter is being processed</Text>
        </div>
    );
};

LetterPreviewDescription.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    letter: PropTypes.instanceOf(SentLetter).isRequired,
};
