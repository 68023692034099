// Models
import { ReplyLetter } from "models/letters/ReplyLetter";
import { SentLetter } from "models/letters/SentLetter";

export function generateReplyLetterInstances(letters) {
    if (letters && letters[0] instanceof ReplyLetter) {
        return letters;
    }
    return letters?.map((letter) => new ReplyLetter(letter));
}

export function generateSentLetterInstances(letters) {
    if (letters && letters[0] instanceof SentLetter) {
        return letters;
    }
    return letters?.map((letter) => new SentLetter(letter));
}
