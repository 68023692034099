import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./letter-preview-info.module.scss";

// Models
import { ReplyLetter } from "models/letters/ReplyLetter";
import { SentLetter } from "models/letters/SentLetter";

export const LetterPreviewInfo = ({ isLoading, letter, tagText }) => {
    const dateText = letter instanceof SentLetter ? "Written on" : "Sent on";

    function renderContent() {
        return (
            <div className={styles.letterPreviewInfo}>
                <div className={styles.name}>
                    <Text>{letter?.recipient?.name}</Text>
                </div>
                <div className={styles.date}>
                    <Text>
                        {dateText} {letter.createdAtPretty}
                    </Text>
                </div>
                <div className={styles.tag}>
                    <Text>{tagText}</Text>
                </div>
            </div>
        );
    }

    function renderLoading() {
        return (
            <div className={styles.letterPreviewInfo}>
                <Skeleton
                    className={cx(
                        styles.skeleton,
                        styles["skeleton--name"],
                        styles.name,
                        "skeleton"
                    )}
                />
                <Skeleton
                    className={cx(
                        styles.skeleton,
                        styles["skeleton--date"],
                        styles.date,
                        "skeleton"
                    )}
                />
                <Skeleton
                    className={cx(
                        styles.skeleton,
                        styles["skeleton--tag"],
                        styles.tag,
                        "skeleton"
                    )}
                />
            </div>
        );
    }

    return isLoading ? renderLoading() : renderContent();
};

LetterPreviewInfo.propTypes = {
    isLoading: PropTypes.bool,
    letter: PropTypes.oneOfType([
        PropTypes.instanceOf(ReplyLetter),
        PropTypes.instanceOf(SentLetter),
    ]),
    tagText: PropTypes.string,
};
