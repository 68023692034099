import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { LetterPreview } from "components/LetterPreview/LetterPreview";
import { LettersSentMenuUSPSNotification } from "./LettersSentMenuUSPSNotification";
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";

// Context
import { HeaderBarContext } from "context/headerBar";
import { NotificationBarContext } from "context/notificationBar";

// CSS
import "css/letters/scenes/Sent/letters-sent.scss";

// Enums
import { LetterType } from "enums/LetterType";

// Hooks
import { Fade } from "hooks/Fade";
import { useDocumentTitle } from "@uidotdev/usehooks";

// Models
import { ReplyLetter } from "models/letters/ReplyLetter";
import { SentLetter } from "models/letters/SentLetter";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import {
    generateReplyLetterInstances,
    generateSentLetterInstances,
} from "./utils/utils";
import { scrollToTop } from "utils/scrollUtils";

// Constants
const showUSPSModalLocal = localStorage.getItem("sandboxxHideUSPSNotification");
const showUSPSModalSession = sessionStorage.getItem(
    "sandboxxHideUSPSNotification"
);

export const LettersSentMenu = (props) => {
    const { history, location } = props;

    /**
     * Custom Hooks
     **/

    useDocumentTitle("Sandboxx - Letters");

    /**
     * useContext
     **/

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     **/

    const [cursorReplied, setCursorReplied] = useState("");
    const [cursorSent, setCursorSent] = useState("");
    const [lettersReplied, setLettersReplied] = useState(null);
    const [lettersSent, setLettersSent] = useState(null);
    const [loading, setLoading] = useState({
        lettersReplied: false,
        lettersSent: false,
        showMoreReplied: false,
        showMoreSent: false,
    });
    const [showUSPSNotification, setShowUSPSNotification] = useState(false);
    const [showUSPSPrompt, setShowUSPSPrompt] = useState(false);

    /**
     * useEffect
     **/

    useEffect(() => {
        setHeaderBarContent({ backPathname: "/letters", text: "History" });
        scrollToTop();
        if (!showUSPSModalLocal && !showUSPSModalSession) {
            setShowUSPSNotification(true);
        }
        if (!lettersSent) {
            if (location.state && location.state.lettersSent) {
                const letters = generateSentLetterInstances(
                    location.state.lettersSent
                );
                setCursorSent(location.state.cursorSent);
                setLettersSent(letters);
            } else {
                fetchLettersSent();
            }
        }
        if (!lettersReplied) {
            if (location.state && location.state.lettersReplied) {
                const letters = generateReplyLetterInstances(
                    location.state.lettersReplied
                );
                setCursorReplied(location.state.cursorReplied);
                setLettersReplied(letters);
            } else {
                fetchLettersReplied();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Constants
     **/

    const lettersMenuListClass =
        lettersSent && lettersSent.length
            ? ""
            : "letters-sent__menu__list--empty";

    /**
     * End Hooks
     **/

    function fetchLettersReplied() {
        setLoading((prevLoading) => ({
            ...prevLoading,
            lettersReplied: true,
            showMoreReplied: true,
        }));
        return SandboxxRestAPI.getLettersReplied(
            cursorReplied,
            onFetchLettersRepliedSuccess,
            onFetchLettersRepliedError,
            onFetchLettersRepliedError
        );
    }

    function fetchLettersSent() {
        setLoading((prev) => ({
            ...prev,
            lettersSent: true,
            showMoreSent: true,
        }));
        return SandboxxRestAPI.getLettersSent(
            cursorSent,
            onFetchLettersSentSuccess,
            onFetchLettersSentError,
            onFetchLettersSentError
        );
    }

    function handleHideUSPSNotification({ isTemporary = true }) {
        if (isTemporary) {
            localStorage.removeItem("sandboxxHideUSPSNotification");
            sessionStorage.setItem("sandboxxHideUSPSNotification", true);
        } else {
            localStorage.setItem("sandboxxHideUSPSNotification", true);
            sessionStorage.removeItem("sandboxxHideUSPSNotification");
        }
        setShowUSPSNotification(false);
    }

    function onFetchLettersRepliedError(err) {
        showNotification({
            notificationBarText:
                "We are unable to fetch your replied letters at this time.",
            notificationBarType: "warning",
        });
    }

    function onFetchLettersSentError(err) {
        showNotification({
            text: "We are unable to fetch your sent letters at this time.",
            type: "warning",
        });
    }

    function onFetchLettersRepliedSuccess(res) {
        const lettersRepliedInstances = res.response.map(
            (letter) => new ReplyLetter(letter)
        );
        const updatedList = cursorReplied
            ? lettersReplied.concat(lettersRepliedInstances)
            : lettersRepliedInstances;
        setCursorReplied(res.cursor);
        setLettersReplied(updatedList);
        setLoading((prev) => ({
            ...prev,
            lettersReplied: false,
            showMoreReplied: false,
        }));
    }

    function onFetchLettersSentSuccess(res) {
        const lettersSentInstances = res.response.map(
            (letter) => new SentLetter(letter)
        );
        const updatedList = cursorSent
            ? lettersSent.concat(lettersSentInstances)
            : lettersSentInstances;
        setCursorSent(res.cursor);
        setLettersSent(updatedList);
        setLoading((prev) => ({
            ...prev,
            lettersSent: false,
            showMoreSent: false,
        }));
    }

    function renderLettersReplied() {
        if (lettersReplied && !lettersReplied.length) {
            return (
                <Fade show={lettersReplied}>
                    <Panel classes="letters-sent__menu__list__no-letters">
                        <Text>You haven't gotten any replied letters yet</Text>
                        <Link to="/letters/compose">
                            <ButtonPrimary>
                                <Text>Start Writing</Text>
                            </ButtonPrimary>
                        </Link>
                    </Panel>
                </Fade>
            );
        }
        if (lettersReplied) {
            return (
                <Fade show={lettersReplied}>
                    {lettersReplied.map((letter) => {
                        const { mailboxxOrderId } = letter;
                        const linkParams = {
                            pathname: `/letters/sent/${mailboxxOrderId}`,
                            state: { letter, letterType: LetterType.REPLIED },
                        };
                        return (
                            <Panel>
                                <LetterPreview
                                    key={letter.mailboxxOrderId}
                                    history={history}
                                    letter={letter}
                                    letterType={LetterType.REPLIED}
                                    linkParams={linkParams}
                                />
                            </Panel>
                        );
                    })}
                </Fade>
            );
        }
        return (
            <>
                <Panel>
                    <LetterPreview isLoading />
                </Panel>
                <Panel>
                    <LetterPreview isLoading />
                </Panel>
                <Panel>
                    <LetterPreview isLoading />
                </Panel>
            </>
        );
    }

    function renderLettersSent() {
        if (lettersSent && !lettersSent.length) {
            return (
                <Fade show={lettersSent}>
                    <Panel classes="letters-sent__menu__list__no-letters">
                        <Text>
                            It looks like you have not sent a letter yet. Click
                            the button below to get started.
                        </Text>
                        <Link to="/letters/compose">
                            <ButtonPrimary>
                                <Text>Start Writing</Text>
                            </ButtonPrimary>
                        </Link>
                    </Panel>
                </Fade>
            );
        }
        if (lettersSent) {
            return (
                <Fade show={lettersSent}>
                    {lettersSent.map((letter) => {
                        const linkParams = {
                            pathname: `/letters/sent/${letter.id}`,
                            state: { letter, letterType: LetterType.SENT },
                        };
                        return (
                            <Panel>
                                <LetterPreview
                                    key={letter.id}
                                    history={history}
                                    letter={letter}
                                    letterType={LetterType.SENT}
                                    linkParams={linkParams}
                                />
                            </Panel>
                        );
                    })}
                </Fade>
            );
        }
        return (
            <>
                <Panel>
                    <LetterPreview isLoading />
                </Panel>
                <Panel>
                    <LetterPreview isLoading />
                </Panel>
                <Panel>
                    <LetterPreview isLoading />
                </Panel>
            </>
        );
    }

    function renderShowMoreLettersReplied() {
        return (
            lettersReplied &&
            cursorReplied && (
                <div className="letters-sent__menu__list__show-more">
                    <ButtonSecondary
                        config={{ shouldMaintainDimensions: true }}
                        isDisabled={!cursorReplied}
                        isLoading={loading.showMoreReplied}
                        onClick={fetchLettersReplied}
                    >
                        <Text>Show More</Text>
                    </ButtonSecondary>
                </div>
            )
        );
    }

    function renderShowMoreLettersSent() {
        return (
            lettersSent &&
            cursorSent && (
                <div className="letters-sent__menu__list__show-more">
                    <ButtonSecondary
                        classes="letters-sent__menu__list__show-more__button"
                        config={{ shouldMaintainDimensions: true }}
                        isDisabled={!cursorSent}
                        isLoading={loading.showMoreSent}
                        onClick={fetchLettersSent}
                    >
                        <Text>Show More</Text>
                    </ButtonSecondary>
                </div>
            )
        );
    }

    return (
        <div className="page">
            <HeaderBar text="Letters Feed" />
            <div className="letters-sent__menu">
                <div className="letters-sent__menu__grid">
                    <div
                        className={`letters-sent__menu__list ${lettersMenuListClass}`}
                    >
                        <div className="letters-sent__menu__list__title">
                            <Text isBold>SENT</Text>
                        </div>
                        {renderLettersSent()}
                        {renderShowMoreLettersSent()}
                    </div>
                    <div
                        className={`letters-sent__menu__list ${lettersMenuListClass}`}
                    >
                        <div className="letters-sent__menu__list__title">
                            <Text isBold>REPLIED</Text>
                        </div>
                        {renderLettersReplied()}
                        {renderShowMoreLettersReplied()}
                    </div>
                </div>
                <LettersSentMenuUSPSNotification
                    handleHideUSPSNotification={handleHideUSPSNotification}
                    setShowUSPSPrompt={setShowUSPSPrompt}
                    showUSPSNotification={showUSPSNotification}
                    showUSPSPrompt={showUSPSPrompt}
                />
            </div>
        </div>
    );
};
